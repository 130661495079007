import { create } from "zustand";

type State = {
  id: string;
  name: string;
  phone: string;
  email: string;
  document: string;
  accountId: string;

  requestId: string;
  token: string;

  last4Digits: string;
};

type Actions = {
  setProperty: (property: string, value: string) => void;
  reset: () => void;
};

const initialState: State = {
  id: "",
  name: "",
  phone: "",
  email: "",
  document: "",
  accountId: "",

  requestId: "",
  token: "",

  last4Digits: "",
};

export const userStore = create<State & Actions>()((set) => {
  return {
    // state
    ...initialState,

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
    reset: () => {
      set(initialState);
    },
  };
});
