import { create } from "zustand";

type State = {
  authententication: string; // 2fa
  authorization: string; // Token BFF
  requestId: string;
  phoneNumber: string;
};

type Actions = {
  setProperty: (state: keyof State, value: State[keyof State]) => void;
  reset: () => void;
};

const initialState = {
  authententication: "",
  authorization: "",
  requestId: "",
  phoneNumber: "",
};

export const authorizationStore = create<State & Actions>()((set) => {
  return {
    // state
    ...initialState,

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
    reset: () => {
      set(initialState);
    },
  };
});
