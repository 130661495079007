import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Form from "./form/Form";
import { FormData, schema } from "./form/schema";

import useResendToken from "hooks/useResendToken";
import { forgetPassword, forgetPasswordConfirm } from "services/user";
import { userStore } from "store";

const ForgotPasswordCode = () => {
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const setProperty = userStore((store) => store.setProperty);
  const requestId = userStore((store) => store.requestId);
  const document = userStore((store) => store.document);

  const navigate = useNavigate();

  async function submitForm(data: FormData) {
    setLoader(true);

    const response = await forgetPasswordConfirm(
      requestId,
      data.validationToken
    );

    if (!response.token) {
      setLoader(false);
      return;
    }

    setProperty("token", response.token);
    setProperty("requestId", response.requestId);

    setLoader(false);
    navigate("/forgot-password-register");
  }

  const {
    handleLoaderResend,
    setActiveTimer,
    loaderResend,
    labelResendButton,
    activeTimer,
  } = useResendToken(30);

  async function handleResendToken() {
    handleLoaderResend(true);

    const { requestId } = await forgetPassword(document);
    if (!requestId) {
      handleLoaderResend(false, true);
      return;
    }

    setProperty("requestId", requestId);
    handleLoaderResend(false);
    setActiveTimer(true);
  }

  function handleBackButton() {
    navigate("/forgot-password-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: {
            handleBackButton: handleBackButton,
            variant: "secondary",
          },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form
          control={control}
          errors={errors}
          resendToken={{
            handleResendToken,
            loaderResend,
            labelResendButton,
            activeTimer,
          }}
        />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordCode;
