import { toast } from "react-toastify";
import { authorizationStore, configurationsStore } from "store";
import { api } from "utilities";
import { validateOnlyNumber } from "utilities/validators";

type SignupUserType = {
  document: string;
  phone: string;
  name: string;
  email: string;
};

export const signUpUser = async (data: SignupUserType) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/sign-up`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      username: validateOnlyNumber(data.document),
      kind: "customer",
      phone: `+55${validateOnlyNumber(data.phone)}`,
      email: data.email,
      name: data.name,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data.user;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const verifyDocument = async (documentNumber: string) => {
  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/accounts/verify-document`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      documentNumber,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const firstLogin = async (accountId: string) => {
  const service = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/accounts/${accountId}/properties`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);

    if (data.firstLogin) {
      toast.error(
        'CNPJ já cadastrado. Retorne para a página inicial e insira seu CNPJ e senha para acessar o portal. Se preferir utilize o recurso "Esqueci minha senha".'
      );
      return false;
    }

    return true;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const patchSignupUser = async (accountId: string, name: string) => {
  const service = {
    method: "PATCH",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/accounts/${accountId}`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      name,
      properties: { firstLogin: true },
    },
  };

  try {
    await api({}).post("/proxy", service);
    return true;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const confirmSignUpUser = async (token: string, userId: string) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/${userId}/confirm`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      token,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch (e: any) {
    if (e.response.data.code === "NotValidSecret") {
      toast.error("O código informado não é válido.");
    } else {
      toast.error("Opss... Algo deu errado!");
    }

    return false;
  }
};

export const registerPassword = async (
  userId: string,
  requestId: string,
  token: string,
  password: string
) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/${userId}/register-password/${requestId}`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      token,
      password,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const forgetPassword = async (document: string) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/forget-password`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      documentNumber: validateOnlyNumber(document),
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const forgetPasswordConfirm = async (
  requestId: string,
  token: string
) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/forget-password/${requestId}/confirm`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      token,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch (e: any) {
    if (e.response.data.code === "NotValidSecret") {
      toast.error("O código informado não é válido.");
    } else {
      toast.error("Opss... Algo deu errado!");
    }

    return false;
  }
};

export const registerNewPassword = async (
  requestId: string,
  token: string,
  password: string
) => {
  const { FID } = configurationsStore.getState();

  const service = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_BANKING_V1}/users/forget-password/${requestId}/register`,
    headers: {
      "Content-Type": "application/json",
      "x-supplier": "biabox",
    },
    data: {
      fid: FID,
      token,
      password,
    },
  };

  try {
    const { data } = await api({}).post("/proxy", service);
    return data;
  } catch {
    toast.error("Opss... Algo deu errado!");
    return false;
  }
};

export const signIn = async (username: string, password: string) => {
  try {
    const { data } = await api({}).post("/users/login", { username, password });

    const { setProperty: setPropertiedAuthorization } =
      authorizationStore.getState();
    setPropertiedAuthorization("authorization", data.accessToken as string);

    return {
      success: true,
      code: data.code,
    };
  } catch (error: any) {
    if (
      error.response?.status === 401 &&
      error.response?.data?.code === "ERR_BFF_WRONG_USERNAME_OR_PASSWORD"
    ) {
      toast.error(
        "Usuário ou senha inválidos, entre com as informações corretas."
      );
    } else if (error.response?.data?.code !== "ERR_BFF_UNTRUSTED_DEVICE") {
      toast.error("Opss... Algo deu errado!");
    }

    return {
      success: false,
      code: error.response?.data?.code,
    };
  }
};
