import { Buffer } from 'buffer'

import CryptoJS, { AES } from 'crypto-js'
import { JSEncrypt } from 'jsencrypt'

export const encryptRSA = async (key: string, data: object) => {
  const RSA = new JSEncrypt()
  const strData = JSON.stringify(data)
  RSA.setPublicKey(key)
  const encrypt = RSA.encrypt(strData)

  return encrypt || ''
}

export const decryptRSA = async (key: string, data: string) => {
  const RSA = new JSEncrypt()
  RSA.setPrivateKey(key)
  const decrypted = RSA.decrypt(data)

  return decrypted || ''
}

export const encryptAES = async (
  aesKey: string,
  data: object,
  cipher?: string,
): Promise<string> => {
  const iv = CryptoJS.enc.Hex.parse(cipher!)
  const key = CryptoJS.enc.Hex.parse(aesKey)

  return AES.encrypt(JSON.stringify(data), key, { iv }).toString()
}

export const decryptAES = async (
  aesKey: string,
  data: string,
  cipher?: string,
) => {
  const key = CryptoJS.enc.Hex.parse(aesKey)
  const iv = CryptoJS.enc.Hex.parse(cipher!)

  const decryptedData = await AES.decrypt(data, key, {
    iv,
  })

  return decryptedData.toString(CryptoJS.enc.Utf8)
}

export const generateRandomString = (length = 32) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomString += characters.charAt(randomIndex)
  }
  return randomString
}

export const generateTokenAES = () => {
  const str = generateRandomString()
  const token = Buffer.from(str).toString('hex')

  return token
}

export const generateCipherAES = () => {
  const byteArray = new Uint8Array(16)
  for (let i = 0; i < 16; i++) {
    byteArray[i] = Math.floor(Math.random() * 256) // Filling the byte array with random values
  }
  return Array.from(byteArray)
    .map((byte) => byte.toString(16).padStart(2, '0')) // Converting each byte to hex and padding with 0 if necessary
    .join('')
}
