import { FormRow } from "components/Layouts/FormLayoutStyles";
import InputText from "components/inputs/InputText";
import { Controller } from "react-hook-form";
import { maskCNPJ, maskPhone } from "utilities";
import { FormPropsType } from "./schema";

export const Form = ({ control, errors }: FormPropsType) => {
  return (
    <>
      <FormRow>
        <Controller
          control={control}
          name="cnpj"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
                mask: maskCNPJ,
                maxLength: 18,
              }}
              label="CNPJ da empresa"
              errorMessage={errors.cnpj?.message}
            />
          )}
        />
      </FormRow>

      <FormRow>
        <Controller
          control={control}
          name="cardName"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                style: { textTransform: "capitalize" },
                change: onChange,
                value: value,
              }}
              label="Nome da empresa"
              errorMessage={errors.cardName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
              }}
              label="E-mail"
              errorMessage={errors.email?.message}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="cellphone"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
                mask: maskPhone,
                maxLength: 15,
              }}
              label="Celular"
              errorMessage={errors.cellphone?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="last4Digits"
          render={({ field: { value, onChange } }) => (
            <InputText
              input={{
                change: onChange,
                value: value,
                maxLength: 4,
              }}
              label="Últimos 4 dígitos do cartão da empresa"
              errorMessage={errors.last4Digits?.message}
            />
          )}
        />
      </FormRow>
    </>
  );
};
