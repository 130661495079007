import icon from "assets/arrow-left.svg";
import InputText from "components/inputs/InputText";
import { maskCNPJ, maskCPF } from "utilities";
import {
  Button,
  Icon,
  Subtitle,
  TextButton,
  TokenTitle,
  TokenSubtitle,
} from "../styles";

type SigninProps = {
  states: {
    username: string;
    password: string;
    token: string;
    loading: boolean;
    isDisabled: boolean;
    hideForm: boolean;
    client: "Bib Infinity" | "Bib Bahamas" | "Bib Corporate";
    authenticateDevice: boolean;
    phoneNumber: string;
  };
  handlers: {
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    setToken: React.Dispatch<React.SetStateAction<string>>;
    setHideForm: (value: boolean) => void;
    handleSubmit: () => any;
    handleNavigateForgotPassword: () => void;
    handleAuthenticateDevice: () => void;
    clearForm: () => void;
  };
};

const Signin = ({
  states: {
    username,
    password,
    loading,
    isDisabled,
    hideForm,
    client,
    authenticateDevice,
    token,
    phoneNumber,
  },
  handlers: {
    setUsername,
    setPassword,
    setToken,
    handleSubmit,
    setHideForm,
    handleNavigateForgotPassword,
    handleAuthenticateDevice,
    clearForm,
  },
}: SigninProps) => {
  return (
    <>
      <Icon
        onClick={() => {
          clearForm();
          setHideForm(!hideForm);
        }}
      >
        <img src={icon} alt="Icon em formato de seta para voltar" />
      </Icon>
      {!authenticateDevice ? (
        <>
          <InputText
            label={client === "Bib Corporate" ? "CNPJ" : "CPF"}
            input={{
              disabled: false,
              mask: client === "Bib Corporate" ? maskCNPJ : maskCPF,
              value: username,
              autoFocus: true,
              change: setUsername,
              maxLength: client === "Bib Corporate" ? 18 : 14,
              placeholder:
                client === "Bib Corporate"
                  ? "00.000.000/0000-00"
                  : "000.000.000-00",
            }}
          />
          <InputText
            label="Senha"
            input={{
              disabled: false,
              value: password,
              change: setPassword,
              type: "password",
              allowShowPassword: true,
              placeholder: "*******",
            }}
          />
        </>
      ) : (
        <>
          <TokenTitle>Autorização de dispositivo</TokenTitle>
          <TokenSubtitle>
            {`Foi enviado um código por SMS para o número ${phoneNumber}, digite-o abaixo para prosseguir.`}
          </TokenSubtitle>
          <InputText
            input={{
              disabled: false,
              value: token,
              autoFocus: true,
              change: setToken,
              maxLength: 6,
              placeholder: "000000",
            }}
          />
        </>
      )}

      <Button
        onClick={authenticateDevice ? handleAuthenticateDevice : handleSubmit}
        disabled={isDisabled || loading}
      >
        {loading ? "Carregando..." : "Entrar no site"}
      </Button>

      {client === "Bib Corporate" && !authenticateDevice ? (
        <TextButton onClick={handleNavigateForgotPassword}>
          Esqueci minha senha
        </TextButton>
      ) : (
        !authenticateDevice && (
          <Subtitle>Mude sua senha através do APP</Subtitle>
        )
      )}
    </>
  );
};

export default Signin;
