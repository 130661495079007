import { create } from "zustand";

type State = {
  environment: "prod" | "sandbox";
  FID: string;
};

type Actions = {
  start: (configs: State) => void;
  setProperty: (state: string, value: string) => void;
};

export const Fid = {
  "Bib Infinity": "442263fb-7c62-420d-86ca-324c13e6f93b",
  "Bib Bahamas": "706a4320-2f3b-4e8c-aa1c-1963daf62d29",
  "Bib Corporate": "20d7f9da-91e6-41bb-9c39-58bcb6cf5511",
};

export const configurationsStore = create<State & Actions>()((set) => {
  return {
    // state
    environment: "sandbox",
    FID: "442263fb-7c62-420d-86ca-324c13e6f93b",

    // actions
    start: (configs) => {
      set(() => ({
        environment: configs.environment,
        FID: configs.FID,
      }));
    },
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
  };
});
