import { deviceSize, FontSize } from "global/styles/sizes.styles";
import styled, { css } from "styled-components";

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: ${deviceSize.lgMobile2}px) {
    background-color: #000;
  }
`;

export const Header = styled.div`
  background: #000;
  height: 12vh;

  display: flex;
  align-items: center;
  padding: 0 24px;
`;

export const ImageLogo = styled.img`
  width: 170px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  height: 100vh;

  @media (max-width: ${deviceSize.lgMobile2}px) {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 24px;

    height: 88vh;
  }

  @media (max-width: ${deviceSize.wxgaplusDesk}px) {
    width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 88vh;

  padding: 0 300px;

  background: #fff;

  @media (max-width: ${deviceSize.wxgaplusDesk}px) {
    padding: 0 192px;
  }

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0 24px;
  }

  @media (max-width: ${deviceSize.lgMobile2}px) {
    border-radius: 24px;
    height: max-content;
    background: #d9d9d9;
    padding: 32px 24px;
  }
`;

export const IamgeBackground = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: linear-gradient(
      rgba(28, 35, 59, 0.5),
      rgba(28, 35, 59, 0.5)
    ),
    url("background.png");
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  border: 0;

  @media (max-width: ${deviceSize.lgMobile2}px) {
    display: none;
  }
`;

export const Title = styled.h1<{ isNormalText?: boolean; mb?: string }>`
  font-size: ${FontSize.large_02};
  font-weight: 400;
  text-transform: ${({ isNormalText }) =>
    isNormalText ? "none" : "uppercase"};
  margin-bottom: ${({ mb }) => (mb ? mb : "96px")};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.larger};
  }

  @media (max-width: ${deviceSize.lgMobile2}px) {
    margin-bottom: 52px;
  }
`;

export const TokenTitle = styled.h1<{
  isNormalText?: boolean;
  mb?: string;
}>`
  font-size: ${FontSize.large_00};
  font-weight: 400;
  text-transform: ${({ isNormalText }) =>
    isNormalText ? "none" : "uppercase"};
  margin-bottom: ${({ mb }) => (mb ? mb : "12px")};
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.medium};
  }
`;

export const Subtitle = styled.p<{ mt?: string }>`
  font-size: ${FontSize.medium};
  margin-top: ${({ mt }) => (mt ? mt : "64px")};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.small_00};
  }

  @media (max-width: ${deviceSize.lgMobile2}px) {
    margin-top: 24px;
  }
`;

export const TokenSubtitle = styled.p<{ mt?: string; mb?: string }>`
  font-size: ${FontSize.medium_00};
  line-height: ${FontSize.large_00};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? mt : "12px")};
  margin-bottom: ${({ mb }) => (mb ? mb : "24px")};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: ${FontSize.small_00};
  }
`;

export const Button = styled.button<{ variant?: "secondary" }>`
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  height: 54px;
  line-height: 54px;
  background-color: ${({ variant }) =>
    variant === "secondary" ? "transparent" : "#000"};
  color: ${({ variant }) => (variant === "secondary" ? "#000" : "#fff")};
  margin-top: 64px;
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      border: 1px solid #000;
    `}

  @media(max-width: ${deviceSize.lgMobile2}px) {
    margin-top: 24px;
  }
`;

export const TextButton = styled.button`
  margin-top: 64px;
`;

export const Icon = styled.div`
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer;
`;
