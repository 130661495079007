import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { patchSignupUser, registerPassword, signIn } from "services/user";
import { userStore } from "store";
import { maskOnlyNumbers } from "utilities";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";

const SignupPassword = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataPropsType>({ resolver: yupResolver(schema) });

  const user = userStore((store) => store);

  async function submitForm(data: FormDataPropsType) {
    setLoader(true);

    const { id, token, requestId } = user;

    const response = await registerPassword(
      id,
      requestId,
      token,
      data.newPassword
    );
    if (!response) {
      setLoader(false);
      return;
    }

    const patch = await patchSignupUser(user.accountId, user.name);
    if (!patch) {
      setLoader(false);
      return;
    }

    await signIn(
      maskOnlyNumbers(user.document),
      data.newPassword
    );

    setLoader(false);

    user.reset();

    navigate("/dialog-page", {
      state: {
        type: "success",
        title: "Tudo certo!",
        subTitle: "Seu cadastro foi realizado com sucesso!",
        buttonText: "Entrar no site",
        routeTo: "/",
      },
    });
  }

  function handleBackButton() {
    navigate("/");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupPassword;
