import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { registerNewPassword } from "services/user";
import { userStore } from "store";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";

const ForgotPasswordRegister = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataPropsType>({
    resolver: yupResolver(schema),
  });

  const requestId = userStore((store) => store.requestId);
  const token = userStore((store) => store.token);
  const reset = userStore((store) => store.reset);

  async function submitForm(data: FormDataPropsType) {
    setLoader(true);

    const response = await registerNewPassword(
      requestId,
      token,
      data.newPassword
    );

    if (typeof response === "boolean" && !response) {
      setLoader(false);
      return;
    }

    reset();
    setLoader(false);

    navigate("/dialog-page", {
      state: {
        type: "success",
        title: "Tudo certo!",
        subTitle: "Sua senha foi criada com sucesso",
        buttonText: "Entrar no site",
        routeTo: "/",
      },
    });
  }

  function handleBackButton() {
    navigate("/forgot-password-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordRegister;
