import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./global/styles";
import Routes from "./routes";

const App = () => {
  return (
    <ThemeProvider theme={{}}>
      <ToastContainer />
      <GlobalStyles />
      <Routes />
    </ThemeProvider>
  );
};

export default App;
