import { create } from "zustand";

type State = {
  public: string;
  tokenAES: string;
  cipherAES: string;
};

type Actions = {
  setProperty: (state: keyof State, value: State[keyof State]) => void;
};

const initialState = {
  public: "",
  cipherAES: "",
  tokenAES: "",
};

export const keyStore = create<State & Actions>()((set) => {
  return {
    // state
    ...initialState,

    // actions
    setProperty: (state, value) => {
      set(() => ({ [state]: value }));
    },
  };
});
