import { DefaultFormPropsType } from "global/types/forms.type";
import { validateCNPJ } from "utilities/validators";
import * as yup from 'yup';


export interface FormData {
  cnpj: string;
}

export interface FormDataPropsType extends DefaultFormPropsType<FormData>{}

export const schema = yup.object<FormData>().shape({
  cnpj: yup.string()
  .required('CNPJ é obrigatório')
  .min(18, "O CNPJ informado é inválido")
  .test('valid-cnpj', 'O CNPJ informado é inválido', (value) => validateCNPJ(value)),
})