import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import useResendToken from "hooks/useResendToken";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { confirmSignUpUser, signUpUser } from "services/user";
import { userStore } from "store";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";

const SignupToken = () => {
  
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataPropsType>({resolver: yupResolver(schema)});

  const user = userStore(store => store)

  async function submitForm(data: FormDataPropsType) {
    
    setLoader(true);

    const { token, requestId } = await confirmSignUpUser(data.validationCode, user.id);
    if(!token || !requestId) { setLoader(false); return; } 

    user.setProperty('token', token)
    user.setProperty('requestId', requestId)

    setLoader(false);
    
    navigate("/signup-password");

  }

  const {
    handleLoaderResend,
    setActiveTimer,
    loaderResend,
    labelResendButton,
    activeTimer
  } = useResendToken(30);
  
  async function handleResendToken(){
    
    handleLoaderResend(true);
    const data = {
      document: user.document, 
      phone: user.phone, 
      email: user.email, 
      name: user.name
    }
    const {id} = await signUpUser(data);
    if(!id) { handleLoaderResend(false, true); return; } 

    user.setProperty('id', id)
    handleLoaderResend(false);
    setActiveTimer(true);

  }

  function handleBackButton() {
    navigate("/signup-initial");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader},
        }}
      >
        <Form control={control} errors={errors} resendToken={{handleResendToken, loaderResend, labelResendButton, activeTimer}} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupToken;
