import { createDevice, getKey } from "actions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deviceAuthorization,
  deviceAuthorizationConfirm,
  signIn,
} from "services";
import {
  authorizationStore,
  configurationsStore,
  deviceStore,
  Fid,
} from "store";
import { maskOnlyNumbers } from "utilities";
import { v4 as uuidv4 } from "uuid";

export const useLoginController = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [client, setClient] = useState<
    "Bib Infinity" | "Bib Bahamas" | "Bib Corporate"
  >("Bib Infinity");
  const [showLogin, setShowLogin] = useState(false);
  const [authenticateDevice, setAuthenticateDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const start = configurationsStore((store) => store.start);
  const setPropertiedDevice = deviceStore((store) => store.setProperty);
  const phoneNumber = authorizationStore((store) => store.phoneNumber);

  // const isDisabled = username.length < (Fid.CORPORATE === fid ? 18 : 14) || (password.length < 8 || password.length > 12);
  const isDisabled =
    username.length < 14 || password.length < 8 || password.length > 12;

  const listOptions = ["Bib Infinity", "Bib Bahamas", "Bib Corporate"];

  const handleNavigateForgotPassword = async () => {
    setLoading(true);
    await startBFF();
    setLoading(false);
    navigate("/forgot-password-initial");
  };

  const handleSubmitClient = async () => {
    setLoading(true);
    await startBFF();
    setShowLogin(true);
    setLoading(false);
  };

  const handleSignupClient = async () => {
    setLoading(true);
    await startBFF();
    setLoading(false);
    navigate("/signup-initial");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { code } = await signIn(maskOnlyNumbers(username), password);

    if (code === "ERR_BFF_UNTRUSTED_DEVICE") {
      const { success } = await deviceAuthorization();
      setLoading(false);

      if (!success) {
        clearForm();
      } else {
        setAuthenticateDevice(true);
      }
    }

    setLoading(false);
  };

  const clearForm = () => {
    setClient("Bib Infinity");
    setUsername("");
    setPassword("");
    setToken("");
    setAuthenticateDevice(false);
  };

  const startBFF = async () => {
    try {
      start({
        FID: Fid[client],
        environment: process.env.REACT_APP_ENV as "sandbox" | "prod",
      });

      setPropertiedDevice("id", uuidv4());

      await getKey();

      await createDevice();

      setLoading(false);
    } catch (error) {
      toast.error(
        `Estamos passando por algumas instabilidades no momento, por favor tente acessar o BIB Rewards mais tarde.`
      );
    }
  };

  const handleAuthenticateDevice = async () => {
    setLoading(true);
    const { success, redirect } = await deviceAuthorizationConfirm(token);
    if (success) {
      window.location.href = `${redirect}`;
    }
    setLoading(false);
  };

  return {
    username,
    password,
    phoneNumber,
    token,
    client,
    loading,
    isDisabled,
    showLogin,
    listOptions,
    authenticateDevice,
    setUsername,
    setPassword,
    setToken,
    setClient,
    setShowLogin,
    handleSubmit,
    handleSubmitClient,
    handleSignupClient,
    handleNavigateForgotPassword,
    setAuthenticateDevice,
    handleAuthenticateDevice,
    clearForm,
  };
};
