import logo from "assets/logo.png";
import {
  Content,
  Form,
  Header,
  IamgeBackground,
  ImageLogo,
  Main,
  Title,
} from "./styles";
import Signin from "./signin";
import Client from "./client";

import { useLoginController } from "./controller";

const Login = () => {
  const {
    showLogin,
    client,
    setClient,
    loading,
    listOptions,
    username,
    setUsername,
    password,
    setPassword,
    isDisabled,
    token,
    setToken,
    phoneNumber,
    authenticateDevice,
    handleSubmit,
    setShowLogin,
    handleSubmitClient,
    handleSignupClient,
    handleNavigateForgotPassword,
    handleAuthenticateDevice,
    clearForm,
  } = useLoginController();

  return (
    <Main>
      <Header>
        <ImageLogo src={logo} alt="logo do bib" />
      </Header>

      <Content>
        <IamgeBackground />
        <Form>
          <Title>BIB Rewards</Title>

          {!showLogin ? (
            <Client
              states={{
                client,
                loading,
                listOptions,
              }}
              handlers={{
                setClient: (client) => setClient(client),
                handleSubmitClient,
                handleSignupClient,
                handleNavigateForgotPassword,
              }}
            />
          ) : (
            <Signin
              states={{
                username,
                password,
                loading,
                isDisabled,
                hideForm: showLogin,
                client: client as any,
                authenticateDevice,
                token,
                phoneNumber,
              }}
              handlers={{
                handleSubmit,
                setPassword,
                setUsername,
                setToken,
                setHideForm: () => setShowLogin(false),
                handleNavigateForgotPassword,
                handleAuthenticateDevice,
                clearForm,
              }}
            />
          )}
        </Form>
      </Content>
    </Main>
  );
};

export default Login;
