import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "services/user";
import { userStore } from "store";
import Form from "./form/Form";
import { FormData, schema } from "./form/schema";

const ForgotPasswordInitital = () => {
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const setProperty = userStore((store) => store.setProperty);

  const navigate = useNavigate();

  async function submitForm(data: FormData) {
    setLoader(true);
    setProperty("document", data.cnpj);

    const { requestId } = await forgetPassword(data.cnpj);
    if (!requestId) {
      setLoader(false);
      return;
    }

    setProperty("requestId", requestId);
    setLoader(false);
    navigate("/forgot-password-code");
  }

  function handleBackButton() {
    navigate("/");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Esqueceu a senha?"
        subTitle="Tudo bem! Vamos gerar uma nova."
        buttons={{
          backButton: {
            variant: "secondary",
            handleBackButton: handleBackButton,
          },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default ForgotPasswordInitital;
