import { yupResolver } from "@hookform/resolvers/yup";
import DefaultLayout from "components/Layouts/DefaultLayout";
import FormLayout from "components/Layouts/FormLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCardByDocument } from "services/cards";
import { firstLogin, signUpUser, verifyDocument } from "services/user";
import { userStore } from "store";
import { validateOnlyNumber } from "utilities/validators";
import { Form } from "./form/form";
import { FormDataPropsType, schema } from "./form/schema";

const SignupInitial = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataPropsType>({ resolver: yupResolver(schema) });

  const user = userStore((store) => store);

  async function submitForm(data: FormDataPropsType) {
    setLoader(true);

    user.setProperty("document", data.cnpj);
    user.setProperty("name", data.cardName);
    user.setProperty("email", data.email);
    user.setProperty("phone", data.cellphone);
    user.setProperty("last4Digits", data.last4Digits);

    const checkAccount = await verifyDocument(validateOnlyNumber(data.cnpj));
    if (!checkAccount) {
      setLoader(false);
      return;
    }

    if (checkAccount.accountId) {
      const checkFirstLogin = await firstLogin(checkAccount.accountId);
      if (!checkFirstLogin) {
        setLoader(false);
        return;
      }
    }

    const cards = await getCardByDocument(
      validateOnlyNumber(data.cnpj),
      data.last4Digits
    );
    if (!cards) {
      setLoader(false);
      return;
    }

    const body = {
      document: data.cnpj,
      phone: data.cellphone,
      email: data.email,
      name: data.cardName,
    };
    const { id, account } = await signUpUser(body);
    if (!id) {
      setLoader(false);
      return;
    }

    user.setProperty("id", id);
    user.setProperty("accountId", account.id);

    navigate("/signup-token");
  }

  function handleBackButton() {
    navigate("/");
  }

  return (
    <DefaultLayout>
      <FormLayout
        title="Cadastro"
        subTitle="Bem-vindo ao BIB Rewards"
        buttons={{
          backButton: { handleBackButton, variant: "secondary" },
          submitButton: { handleSubmit: handleSubmit(submitForm), loader },
        }}
      >
        <Form control={control} errors={errors} />
      </FormLayout>
    </DefaultLayout>
  );
};

export default SignupInitial;
