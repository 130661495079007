import InputText from "components/inputs/InputText";
import { Controller } from "react-hook-form";
import { maskCNPJ } from "utilities";
import { FormDataPropsType } from "./schema";

const Form = ({ control, errors }: FormDataPropsType) => {
  return (
    <Controller
      control={control}
      name="cnpj"
      render={({ field: { value, onChange } }) => (
        <InputText
          errorMessage={errors.cnpj?.message}
          label="Digite o CNPJ cadastrado"
          input={{
            value: value,
            change: onChange,
            maxLength: 18,
            mask: maskCNPJ,
          }}
        />
      )}
    />
  );
};

export default Form;
