import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { authorizationStore, deviceStore } from "store";
import { api } from "utilities";

export const postDevice = async (data: unknown) => {
  try {
    const response = await api({}).post("/devices", data);

    return response;
  } catch (error: any) {
    console.log("ERROR IN REGISTER DEVICE ==> ", error);

    return error.response;
  }
};

export const deviceAuthorization = async (type: "sms" | "email" = "sms") => {
  try {
    const { id } = deviceStore.getState();

    const { data } = await api({}).get(
      `/devices/${id}/authorization?type=${type}`
    );

    const { setProperty } = authorizationStore.getState();

    setProperty("phoneNumber", data.phoneNumber);
    setProperty("requestId", data.requestId);

    return {
      phoneNumber: data.phoneNumber,
      requestId: data.requestId,
      success: true,
    };
  } catch (error) {
    const errorFormatted = error as AxiosError;
    const response = errorFormatted.response as AxiosResponse<{
      code: string;
      message: string;
    }>;

    toast.error(response.data.message);

    return {
      ...response,
      success: false,
    };
  }
};

export const deviceAuthorizationConfirm = async (token: string) => {
  try {
    const { id } = deviceStore.getState();
    const { requestId } = authorizationStore.getState();

    const data = {
      requestId,
      token,
    };

    const response = await api({}).patch(
      `/devices/${id}/authorization/rewards`,
      data
    );

    return {
      ...response.data,
      success: true,
    };
  } catch (error) {
    const errorFormatted = error as AxiosError;
    const response = errorFormatted.response as AxiosResponse<{
      code: string;
      message: string;
    }>;

    toast.error(response.data.message);

    return {
      ...response,
      success: false,
    };
  }
};
